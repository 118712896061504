import React, { useEffect, useMemo, useState } from "react";

import { useQuery } from "@tanstack/react-query";
import { format, isSameDay } from "date-fns";
import { HiChevronLeft, HiChevronRight, HiTrash } from "react-icons/hi2";
import { toast } from "react-toastify";

import {
  TimeSlot,
  computeAvailableSlots,
  getSlotsWithNoSeatsAvailable,
} from "@booking/helpers/appointments";
import { getSellerInformations } from "@booking/helpers/booking";
import {
  extractFormat,
  extractMeetingApp,
} from "@booking/helpers/virtual-apps-handler";
import Tag from "@components/data-display/Tag";
import Tooltip from "@components/data-display/Tooltip";
import Button from "@components/data-entry/Button";
import CalloutBox from "@components/feedback/CalloutBox";
import Drawer, { useDrawer } from "@components/feedback/Drawer";
import BottomBar from "@components/layout/BottomBar";
import Breakpoint from "@components/layout/Breakpoint";
import { arrayFilterUnique, toNonEmptyArray } from "@helpers/Array";
import {
  LocalDate,
  ZonedDate,
  combineDateAndTime,
  getStartAndEnd,
} from "@helpers/Date";
import { usePrefixedTranslation } from "@helpers/Translation";
import { Account } from "@models/Account";
import { Collection } from "@models/Collection";
import { Contact } from "@models/Contact";
import { Showroom } from "@models/Showroom";
import {
  AppointmentFormat,
  AppointmentFormatWithMeetingApp,
  AppointmentTypeEnum,
  ShowroomSeason,
  VirtualMeetingApps,
} from "@models/types/enums";
import { BookingBookAppointmentEndpoint } from "@services/api/booking/book-appointment";
import { useUpdateBookingAppointment } from "@services/api/old/booking/update-appointment";
import getShowroomAppointmentsEndpoint from "@services/api/old/showroom/appointments";
import {
  getNextOpeningDate,
  getOpeningDates,
  getShowroomKeyAccountsHoursOnDate,
  getShowroomOtherAccountsHoursOnDate,
  hasKeyAccountsSlots,
} from "@shared/showroom/helpers";

import AppointmentCollectionList from "./appointment/collection-list";
import AppointmentSlotCalendar from "./appointment/slot-select-calendar";
import AppointmentSlotFilters from "./appointment/slot-select-filters";
import SpecialRequestForm from "./appointment/special-request-form";
import AvailableTimeSlot from "./available-timeslot";
import { useBookingContext } from "./hook";
import BookedTimeSlot from "./timeslot-booked";

export interface SlotAppointmentData {
  showroom: {
    id: string;
    season: ShowroomSeason;
    year: number;
  };
  seller: {
    id: string;
  };
  collection: {
    id: string;
  } | null;
  account: {
    id: string;
  };
  attendees: [
    {
      id: string;
    },
    ...{
      id: string;
    }[],
  ];
  startTime: ZonedDate;
  endTime: ZonedDate;
  format: AppointmentFormat;
  virtualMeetingApp: VirtualMeetingApps | null;
  type: AppointmentTypeEnum;
  accountOtb: number | null;
  collectionInterests: { id: string }[];
}

interface Budget {
  accountId: string;
  collectionId: string;
  otb: number | null;
  season: ShowroomSeason;
  year: number;
}

type Props = {
  budgets: Budget[];
  editedAppointmentId?: string;
  invitationId: string;
  organization: {
    name: string;
  };
  contact: Pick<Contact, "id" | "firstName" | "lastName"> & {
    account: Pick<Account, "id" | "name" | "isKeyClient">;
  };
  onPrevious: () => void;
  onSubmit: (
    appointments: { id: string }[],
    specialRequests: { id: string }[],
  ) => void;
  showrooms: (Pick<
    Showroom,
    | "id"
    | "appointmentFormats"
    | "openingDays"
    | "openingHour"
    | "closingHour"
    | "appointmentTypesDuration"
    | "lunchBreakStartingHour"
    | "lunchBreakEndingHour"
    | "numberOfSeats"
    | "timezone"
    | "appointmentIncrement"
    | "specialRequestsAllowed"
  > & {
    sellers: {
      id: string;
      appointmentTypes: AppointmentTypeEnum[];
      languages: string[];
      virtualMeetingAppLinks: Partial<
        Record<VirtualMeetingApps, string>
      > | null;
    }[];
    collections: Collection[];
  })[];
  portfolios: {
    collectionId: string | null;
    sellers: { id: string }[];
  }[];
};

export default function SlotSelect({
  budgets,
  editedAppointmentId,
  contact,
  onPrevious,
  onSubmit,
  showrooms,
  portfolios,
  invitationId,
  organization,
}: Props) {
  const { pt, t } = usePrefixedTranslation("Booking.SlotSelect");
  const { state, dispatch, selectBookedAppointments, selectSpecialRequests } =
    useBookingContext();
  const { mutateAsync: bookAppointment } =
    BookingBookAppointmentEndpoint.useHook({ invitationId });
  const { mutateAsync: updateAppointment } =
    useUpdateBookingAppointment(invitationId);

  const specialRequestDrawer = useDrawer();
  const [filterValues, setFilterValues] = useState({
    formats: [] as AppointmentFormatWithMeetingApp[],
    languages: [] as string[],
  });

  const [currentTab, setCurrentTab] = useState(0);

  const goToNextAppointment = () => {
    const nextAppointmentIndex = state.appointments.findIndex(
      (appt, index) =>
        !appt.bookedAppointment &&
        !appt.cancelled &&
        !appt.specialRequest &&
        index !== currentTab,
    );
    if (nextAppointmentIndex !== -1) {
      setCurrentTab(nextAppointmentIndex);
    }
  };
  // switch current tab when the current tab is booked/special_requested/cancelled
  useEffect(() => {
    const currentAppt = state.appointments[currentTab];
    const shouldGoToNext =
      currentAppt.bookedAppointment ||
      currentAppt.specialRequest ||
      currentAppt.cancelled;
    if (shouldGoToNext) {
      goToNextAppointment();
    }
    //! DO NOT ADD currentTab to the dependencies, because it only triggers when state changes, not currentTab
  }, [state]);

  const [expandedElement, setExpandedElement] =
    useState<HTMLAnchorElement | null>();
  useEffect(() => {
    if (expandedElement) {
      expandedElement.scrollIntoView(true);
    }
  }, [expandedElement]);

  const currentAppointment = state.appointments[currentTab];

  const showroom = showrooms.find(
    (s) => s.id === currentAppointment.showroomId,
  );

  // slots props
  const { data: showroomBookedSlots = [] } = useQuery({
    ...getShowroomAppointmentsEndpoint.query(invitationId, showroom?.id || ""),
  });

  if (!showroom) {
    throw new Error(pt("showroom-not-found"));
  }

  const currentCollection =
    showroom.collections.find((c) =>
      "collection" in currentAppointment
        ? c.id === currentAppointment.collection.id && c
        : false,
    ) || null;

  // automatically go to the next opening date for that showroom
  const nextOpeningDate = getNextOpeningDate(
    showroom,
    contact.account.isKeyClient,
  );
  const [selectedDate, setSelectedDate] = useState<Date>(
    nextOpeningDate || new Date(),
  );
  useEffect(() => {
    setSelectedDate(nextOpeningDate || new Date());
  }, [showroom.id]);

  const bookedAppointments = selectBookedAppointments();
  const specialRequests = selectSpecialRequests();

  const sellerInformations = getSellerInformations({
    accountPortfolios: portfolios,
    currentStep: 3,
    selectedAppointmentsFormats: filterValues.formats,
    selectedLanguages: filterValues.languages,
    selectedAppointmentType: currentAppointment.type,
    showroom,
  });

  const availableFormats = sellerInformations
    .flatMap((si) => si.formatsWithMeetingApps)
    .filter(arrayFilterUnique);

  const availableLanguages = showroom.sellers
    .flatMap((si) => si.languages)
    .filter(arrayFilterUnique);

  const { opening, closing } =
    hasKeyAccountsSlots(showroom) && contact.account.isKeyClient
      ? getShowroomKeyAccountsHoursOnDate(selectedDate, showroom)
      : getShowroomOtherAccountsHoursOnDate(selectedDate, showroom);

  const appointmentDuration =
    showroom.appointmentTypesDuration.find((atd) =>
      currentAppointment.type === AppointmentTypeEnum.WALKTHROUGH
        ? atd.type === currentAppointment.type
        : atd.type === currentAppointment.type &&
          atd.collectionId === currentAppointment.collection.id,
    )?.duration || 0;

  const bookedSlots = (showroomBookedSlots || []).map((bookedAppt) => ({
    sellerId: bookedAppt.sellerId,
    isCompetitor: bookedAppt.isCompetitor,
    start: bookedAppt.startTime.toLocalDate(showroom.timezone),
    end: bookedAppt.endTime.toLocalDate(showroom.timezone),
    type: bookedAppt.type,
  }));

  const breaks: TimeSlot[] = useMemo(() => {
    const result: TimeSlot[] = [];
    // add lunch break
    if (showroom.lunchBreakStartingHour && showroom.lunchBreakEndingHour) {
      result.push({
        start: combineDateAndTime(
          selectedDate,
          showroom.lunchBreakStartingHour,
        ),
        end: combineDateAndTime(selectedDate, showroom.lunchBreakEndingHour),
      });
    }

    // On the selected date, get the slots where all seats are taken
    // When there are no seat available, behave like a lunch break
    return result.concat(
      getSlotsWithNoSeatsAvailable(
        showroom.numberOfSeats,
        bookedSlots.filter(
          (s) => s.type !== "BUSY" && isSameDay(s.start, selectedDate),
        ),
      ),
    );
  }, [bookedSlots, selectedDate, showroom]);

  const availableSlots = React.useMemo(
    () =>
      computeAvailableSlots(
        new Date(), // disable slots that are before this time and date
        combineDateAndTime(selectedDate, opening),
        combineDateAndTime(selectedDate, closing),
        appointmentDuration,
        breaks,
        sellerInformations,
        bookedSlots,
        filterValues,
        showroom.appointmentIncrement,
      ),
    [
      selectedDate,
      opening,
      closing,
      appointmentDuration,
      breaks,
      sellerInformations,
      bookedSlots,
      filterValues,
      showroom.appointmentIncrement,
    ],
  );

  const openingDates = getOpeningDates(showroom, contact.account.isKeyClient);
  const [showroomOpeningDate, showroomClosingDate] =
    getStartAndEnd(openingDates);

  // button is disabled if
  // - all appointments were cancelled... Why bother with modaresa ?
  // - there is at least one appointment that is not booked, not cancelled and has no special request
  const allAppointmentsAreCancelled = state.appointments.every(
    (appt) => appt.cancelled,
  );
  const isDisabledNextButton =
    allAppointmentsAreCancelled ||
    state.appointments.some(
      (appt) =>
        !appt.bookedAppointment && !appt.cancelled && !appt.specialRequest,
    );

  return (
    <div className="flex flex-col lg:flex-row w-full">
      <div className="flex flex-col w-full lg:w-1/4 border-r-primaryLightGrey border-r">
        {/* pagination box */}
        <div className="p-6 flex gap-4 items-center border-b border-b-primaryLightGrey">
          <Tag>{editedAppointmentId ? "Edit" : "3/4"}</Tag>
          <h3 className="heading-3-mobile lg:heading-3">
            {pt(editedAppointmentId ? "title-edit" : "title")}
          </h3>
        </div>
        {/** APPOINTMENT LIST */}
        <AppointmentCollectionList
          showroom={showroom}
          activeTab={currentTab}
          onSelect={(index) => {
            setCurrentTab(index);
          }}
          onClickSpecialRequest={specialRequestDrawer.openDrawer}
        />
        {/** BOTTOM BAR */}
        <Breakpoint from="lg">
          <BottomBar className="flex justify-between">
            <Button onClick={onPrevious} theme="TERTIARY">
              <HiChevronLeft className="w-5 h-5" />
              {t("Common.previous")}
            </Button>
            <Tooltip
              content={t(
                "Booking.SlotSelect.one-appointment-needed-to-continue",
              )}
              renderIf={allAppointmentsAreCancelled}
              fallbackProp="children"
            >
              <Button
                onClick={() => onSubmit(bookedAppointments, specialRequests)}
                theme="PRIMARY"
                type="submit"
                disabled={isDisabledNextButton}
              >
                {bookedAppointments?.length > 0
                  ? t("Common.next")
                  : t("Common.finish")}
                <HiChevronRight className="w-5 h-5" />
              </Button>
            </Tooltip>
          </BottomBar>
        </Breakpoint>
      </div>

      <main className="w-full lg:w-3/4 py-4 px-6 flex flex-col md:gap-8">
        <AppointmentSlotFilters
          formats={availableFormats}
          languages={availableLanguages}
          values={filterValues}
          onFormatChange={(formats) => {
            setFilterValues((prev) => ({ ...prev, formats }));
          }}
          onLanguageChange={(langs) => {
            setFilterValues((prev) => ({ ...prev, languages: langs }));
          }}
        />

        {currentAppointment.bookedAppointment ? (
          <BookedTimeSlot
            appointment={currentAppointment.bookedAppointment}
            timezone={showroom.timezone}
            invitationId={invitationId}
            deleteInBackend={!editedAppointmentId}
            onDelete={() => {
              dispatch({
                type: "DELETE_BOOKED_APPOINTMENT",
                index: currentTab,
              });
            }}
          />
        ) : (
          <div className="flex lg:gap-6 items-center lg:items-start flex-col lg:flex-row">
            <AppointmentSlotCalendar
              minDate={format(
                Math.max(Date.now(), showroomOpeningDate.getTime()),
                "Y/MM/d",
              )}
              maxDate={format(showroomClosingDate, "Y/MM/d")}
              availableDates={openingDates}
              selectedDate={selectedDate}
              onDateSelected={(date) => {
                setSelectedDate(date);
              }}
            />
            <div className="mt-4 w-full lg:mt-0 lg:max-h-[45vh] overflow-y-scroll lg:grow lg:pr-4 scroll-smooth">
              {availableSlots.length === 0 && (
                <CalloutBox className="space-y-2">
                  <p>
                    {t(
                      "Booking.AppointmentBookingSlotSelect.no-slot-available",
                    )}
                  </p>
                  <p>
                    {t(
                      "Booking.AppointmentBookingSlotSelect.modify-your-criterias",
                    )}
                  </p>
                  <p>
                    {t(
                      "Booking.AppointmentBookingSlotSelect.contact-the-organization-if-still-no-slots-available",
                    )}
                  </p>
                  <Button
                    className="mt-4"
                    theme="PRIMARY"
                    onClick={() => {
                      setFilterValues({ formats: [], languages: [] });
                    }}
                  >
                    <HiTrash />
                    {t("Booking.AppointmentBookingSlotSelect.reset-filters")}
                  </Button>
                </CalloutBox>
              )}
              {availableSlots.map((appointmentSlot) => (
                <AvailableTimeSlot
                  onExpand={(anchor) => {
                    setExpandedElement(anchor);
                  }}
                  expanded={
                    !!expandedElement &&
                    appointmentSlot.key === expandedElement.id
                  }
                  filterValues={filterValues}
                  key={appointmentSlot.key}
                  id={appointmentSlot.key}
                  start={appointmentSlot.start}
                  end={appointmentSlot.end}
                  timeZone={showroom.timezone}
                  onBook={(slot) => {
                    const attendeesIds = toNonEmptyArray(
                      currentAppointment.attendees.map(({ id }) => ({ id })),
                    );

                    const data: SlotAppointmentData = {
                      seller: {
                        id: slot.matchingSellers[0].id,
                      },
                      showroom: {
                        id: showroom.id,
                        season: "SS",
                        year: 2025,
                        // season: showroom.season,
                        // year: showroom.year,
                      },
                      account: {
                        id: contact.account.id,
                      },
                      collection:
                        "collection" in currentAppointment
                          ? { id: currentAppointment.collection.id }
                          : null,
                      startTime: ZonedDate.fromLocalDate(
                        slot.start as LocalDate,
                        showroom.timezone,
                      ),
                      endTime: ZonedDate.fromLocalDate(
                        slot.end as LocalDate,
                        showroom.timezone,
                      ),
                      type: currentAppointment.type,
                      format: extractFormat(slot.format),
                      attendees: attendeesIds,
                      accountOtb:
                        "collection" in currentAppointment
                          ? budgets.find(
                              (budget) =>
                                budget.collectionId ===
                                currentAppointment.collection.id,
                            )?.otb || null
                          : null,
                      virtualMeetingApp: extractMeetingApp(slot.format) || null,
                      collectionInterests:
                        currentAppointment.collectionInterests,
                    };

                    return (
                      editedAppointmentId
                        ? updateAppointment({
                            appointmentId: editedAppointmentId,
                            appointment: { id: editedAppointmentId, ...data },
                          })
                        : bookAppointment({ data })
                    ).then((newBookedAppointment) => {
                      toast.success(
                        t(
                          "Booking.AppointmentBookingSlotSelect.confirm-appointment-booking",
                        ),
                      );
                      // update state with new appointment
                      dispatch({
                        type: "BOOKED_APPOINTMENT",
                        index: currentTab,
                        appointment: {
                          ...newBookedAppointment,
                          warnings: newBookedAppointment.warnings || [],
                        },
                      });

                      // reset expanded slot
                      setExpandedElement(undefined);
                      // scroll to top
                      window.scrollTo({ top: 0, behavior: "smooth" });
                    });
                  }}
                  sellerInformations={appointmentSlot.sellerInformations}
                  disabled={appointmentSlot.disabled}
                />
              ))}
            </div>
          </div>
        )}
      </main>

      <Breakpoint to="lg">
        <BottomBar className="flex justify-between">
          <Button onClick={onPrevious} theme="TERTIARY">
            <HiChevronLeft className="w-5 h-5" />
            {t("Common.previous")}
          </Button>
          <Button
            onClick={() => onSubmit(bookedAppointments, specialRequests)}
            theme="PRIMARY"
            type="submit"
            disabled={isDisabledNextButton}
          >
            {t("Common.next")}
            <HiChevronRight className="w-5 h-5" />
          </Button>
        </BottomBar>
      </Breakpoint>

      <Drawer
        {...specialRequestDrawer.props}
        isOpen={specialRequestDrawer.isOpen}
        name="creating or updating special request drawer"
        backdrop
        drawerTitle={
          <h2 className="heading-2-mobile lg:heading-2">
            {pt("special-request-form.drawer-title")}
          </h2>
        }
        size="LARGE"
      >
        <SpecialRequestForm
          invitationId={invitationId}
          account={contact.account}
          organization={organization}
          showroom={showroom}
          collection={currentCollection}
          appointmentType={currentAppointment.type}
          onSuccess={(specialRequest) => {
            dispatch({
              type: "BOOKED_SPECIAL_REQUEST",
              index: currentTab,
              specialRequest,
            });
            specialRequestDrawer.closeWithoutConfirmation();
          }}
          onError={specialRequestDrawer.closeWithoutConfirmation}
          onCancel={specialRequestDrawer.closeWithoutConfirmation}
        />
      </Drawer>
    </div>
  );
}

import {
  compareAsc,
  compareDesc,
  format,
  isAfter,
  isBefore,
  isSameDay,
} from "date-fns";

import { hasAtLeastOneItem } from "@helpers/Array";
import { combineDateAndTime, formatDateToISO, getUTCDate } from "@helpers/Date";
import { Showroom } from "@models/Showroom";

type OpeningHoursConfig = Pick<
  Showroom,
  "openingDays" | "openingHour" | "closingHour"
>;
type OpeningDay = Showroom["openingDays"][number];
type OpeningDayWithOnlyDate = { day: Date };
type ObjectWithOpeningDays<T extends OpeningDayWithOnlyDate> = {
  openingDays: T[];
};
type ObjectWithClosingHour<T> = { closingHour: Date } & T;

export function buildOpeningDay(day: Date) {
  return {
    day,
    keyAccountsOpeningHour: null,
    keyAccountsClosingHour: null,
    customOpeningHour: null,
    customClosingHour: null,
  };
}

function isAvailableForKeyAccount(openingDay: OpeningDay) {
  return (
    openingDay.keyAccountsOpeningHour !== null &&
    openingDay.keyAccountsClosingHour !== null
  );
}

function isAvailableForNonKeyAccount(
  openingDay: OpeningDay,
  closingHour: Date,
) {
  return (
    openingDay.keyAccountsClosingHour === null ||
    isAfter(
      openingDay.customClosingHour || closingHour,
      openingDay.keyAccountsClosingHour,
    )
  );
}

export function getOpeningDates(
  showroom: ObjectWithClosingHour<ObjectWithOpeningDays<OpeningDay>>,
  isKeyAccount?: boolean,
): Date[] {
  let filterFn: (od: OpeningDay) => boolean = () => true;
  if (isKeyAccount === true) {
    filterFn = (od: OpeningDay) => isAvailableForKeyAccount(od);
  } else if (isKeyAccount === false) {
    filterFn = (od: OpeningDay) =>
      isAvailableForNonKeyAccount(od, showroom.closingHour);
  }

  return showroom.openingDays.filter(filterFn).map((od) => od.day);
}

export function getFirstOpeningDay<T extends OpeningDayWithOnlyDate>({
  openingDays,
}: ObjectWithOpeningDays<T>) {
  openingDays.sort((a, b) => compareAsc(a.day, b.day));
  hasAtLeastOneItem(openingDays);
  return openingDays[0];
}

export function getLastOpeningDay<T extends OpeningDayWithOnlyDate>({
  openingDays,
}: ObjectWithOpeningDays<T>) {
  openingDays.sort((a, b) => compareDesc(a.day, b.day));
  hasAtLeastOneItem(openingDays);
  return openingDays[0];
}

export default function getLastOpeningDate<T extends OpeningDayWithOnlyDate>({
  openingDays,
}: ObjectWithOpeningDays<T>) {
  const lastOpeningDay = getLastOpeningDay({ openingDays });
  return lastOpeningDay.day;
}

export function getFirstOpeningDate<T extends OpeningDayWithOnlyDate>({
  openingDays,
}: ObjectWithOpeningDays<T>) {
  const firstOpeningDay = getFirstOpeningDay({ openingDays });
  return firstOpeningDay.day;
}

export function getOpeningDayForDate<T extends OpeningDayWithOnlyDate>(
  date: Date,
  showroom: ObjectWithOpeningDays<T>,
): T | undefined {
  return showroom.openingDays.find(
    (od) => formatDateToISO(date) === formatDateToISO(od.day),
  );
}

export function getCustomHoursConfig(
  openingDays: {
    day: Date;
    customOpeningHour: Date | null;
    customClosingHour: Date | null;
  }[],
) {
  return openingDays.reduce(
    (acc, curr) => ({
      ...acc,
      [format(curr.day, "yyyy-MM-dd")]: {
        openingHour: curr.customOpeningHour,
        closingHour: curr.customClosingHour,
      },
    }),
    {} as Record<
      string,
      { openingHour: Date | null; closingHour: Date | null }
    >,
  );
}

export function getShowroomHoursOnDate(
  selectedDate: Date,
  showroom: OpeningHoursConfig,
) {
  const { openingHour, closingHour } = showroom;
  const openingDay = showroom.openingDays.find((od) =>
    isSameDay(od.day, selectedDate),
  );
  return {
    opening: openingDay?.customOpeningHour || openingHour,
    closing: openingDay?.customClosingHour || closingHour,
  };
}

export function hasKeyAccountsSlots({ openingDays }: OpeningHoursConfig) {
  return !openingDays.every(
    (od) =>
      od.keyAccountsClosingHour === null && od.keyAccountsOpeningHour === null,
  );
}

export function getShowroomKeyAccountsHoursOnDate(
  selectedDate: Date,
  showroom: OpeningHoursConfig,
) {
  const result = getShowroomHoursOnDate(selectedDate, showroom);
  const openingDay = getOpeningDayForDate(selectedDate, showroom);

  if (!openingDay) {
    return {
      opening: selectedDate,
      closing: selectedDate,
    };
  }

  return {
    opening: openingDay.keyAccountsOpeningHour
      ? combineDateAndTime(selectedDate, openingDay.keyAccountsOpeningHour)
      : result.opening,
    closing: openingDay.keyAccountsClosingHour
      ? combineDateAndTime(selectedDate, openingDay.keyAccountsClosingHour)
      : result.opening,
  };
}

export function getShowroomOtherAccountsHoursOnDate(
  selectedDate: Date,
  showroom: OpeningHoursConfig,
) {
  const result = getShowroomHoursOnDate(selectedDate, showroom);
  const openingDay = getOpeningDayForDate(selectedDate, showroom);
  if (!openingDay) {
    return {
      opening: selectedDate,
      closing: selectedDate,
    };
  }
  return {
    opening: openingDay.keyAccountsClosingHour
      ? combineDateAndTime(selectedDate, openingDay.keyAccountsClosingHour)
      : result.opening,
    closing: result.closing,
  };
}

export function isShowroomOver(showroom: ObjectWithOpeningDays<OpeningDay>) {
  return isBefore(getLastOpeningDate(showroom), new Date());
}

export function hasBookingDeadlinePassed(date: Date) {
  return new Date(date.toDateString()) < new Date(new Date().toDateString());
}

export function getNextOpeningDate(
  showroom: OpeningHoursConfig,
  isKeyAccount: boolean = false,
): Date | undefined {
  if (isShowroomOver(showroom)) {
    return undefined;
  }

  // get the opening dates in ascending order
  const openingDates = showroom.openingDays.sort((l, r) =>
    compareAsc(l.day, r.day),
  );
  // use getUTCDate because openingDays are all UTC dates
  const utcToday = getUTCDate();

  // find the first one that is today or after
  const nextOpeningDate = openingDates.find((od) => {
    const openingHoursThatDay = getShowroomHoursOnDate(od.day, showroom);
    const isTodayOrAfter =
      isSameDay(utcToday, od.day) || isAfter(od.day, utcToday);
    const isOpenForKeyAccounts =
      od.keyAccountsClosingHour !== null &&
      isAfter(od.keyAccountsClosingHour, openingHoursThatDay.opening);
    const isOpenForOtherAccounts =
      od.keyAccountsClosingHour === null ||
      isAfter(openingHoursThatDay.closing, od.keyAccountsClosingHour);

    return (
      isTodayOrAfter &&
      ((isKeyAccount && isOpenForKeyAccounts) ||
        (!isKeyAccount && isOpenForOtherAccounts))
    );
  });

  return nextOpeningDate?.day || new Date();
}
